import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/layout/home/home.component';
import { TechnologiesComponent } from './components/pages/technologies/technologies.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component'
import { WebDevelopmentComponent } from './components/pages/services/web-development/web-development.component'
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component'
import { ThankYouComponent } from './components/pages/thank-you/thank-you.component'
import { MobileDevelopmentComponent } from './components/pages/services/mobile-development/mobile-development.component'
import { OurWorkComponent } from './components/pages/our-work/our-work.component';
import { ECommerceDevelopmentComponent } from './components/pages/services/e-commerce-development/e-commerce-development.component'
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { ArtificialInteligenceComponent } from './components/pages/services/artificial-inteligence/artificial-inteligence.component';
import { DevopsServicesComponent } from './components/pages/services/devops-services/devops-services.component';
import { BlockChainComponent } from './components/pages/services/block-chain/block-chain.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent},
      { path: 'contact-us', component: ContactUsComponent },
      { path: 'technologies', component: TechnologiesComponent },
      { path: 'web-development', component: WebDevelopmentComponent },
      { path: 'mobile-development', component: MobileDevelopmentComponent },
      { path: 'e-commerce-development', component: ECommerceDevelopmentComponent },
      { path: 'thank-you', component:ThankYouComponent},
      { path: 'our-work', component: OurWorkComponent },
      { path: 'mobile-development', component: MobileDevelopmentComponent },
      { path: 'about-us', component: AboutUsComponent },
      { path: 'blockchain', component: BlockChainComponent },
      { path: 'artificial-inteligence', component: ArtificialInteligenceComponent },
      { path:'devops-services', component:DevopsServicesComponent}
    ],
  },
  {path: '404', component: PageNotFoundComponent},
  {path: '**', redirectTo: '/404'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
