import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { contactUs } from './contact-us'

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  readonly url = 'https://dev.classoto.in/devapi/contactweoto';

  readonly options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private http: HttpClient) { }

  contactUs(contact:contactUs){
    console.log(contact)
    return this.http.post<contactUs>(this.url,JSON.stringify(contact),this.options)
  }
}
