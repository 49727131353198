import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public isHome: BehaviorSubject<boolean>;

  constructor() { 
    this.isHome = new BehaviorSubject<boolean>(false);
  }
}
