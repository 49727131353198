import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HomeComponent } from './components/layout/home/home.component';
import { TechnologiesComponent } from './components/pages/technologies/technologies.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { WebDevelopmentComponent } from './components/pages/services/web-development/web-development.component';
import { MobileDevelopmentComponent } from './components/pages/services/mobile-development/mobile-development.component'
import { CommonService } from '../app/components/common/common.service'
import { CarouselModule } from 'ngx-owl-carousel-o';
// import { NgxTypedJsModule } from 'ngx-typed-js';
import { OurWorkComponent } from './components/pages/our-work/our-work.component'
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ThankYouComponent } from './components/pages/thank-you/thank-you.component'
import { HttpClientModule } from '@angular/common/http';
import { ECommerceDevelopmentComponent } from './components/pages/services/e-commerce-development/e-commerce-development.component'
import {NgxTypedJsModule} from 'ngx-typed-js';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { ArtificialInteligenceComponent } from './components/pages/services/artificial-inteligence/artificial-inteligence.component';
import { DevopsServicesComponent } from './components/pages/services/devops-services/devops-services.component';
import { BlockChainComponent } from './components/pages/services/block-chain/block-chain.component';
import { AppModule } from './app.module';

@NgModule({
  imports: [
    
    AppRoutingModule,
    ReactiveFormsModule, 
    FormsModule,
    CarouselModule,
    NgxTypedJsModule,
    
    HttpClientModule,
    FormsModule,
    AppModule,
    BrowserTransferStateModule
  ],
  providers: [CommonService],
  bootstrap: [AppComponent]
})
export class AppBrowserModule { }
