import { Component, OnInit } from '@angular/core';
// import Typed from 'typed.js';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  // typed3 = new Typed('#typed3', {
  //   strings: ['My strings are: <i>strings</i> with', 'My strings are: <strong>HTML</strong>', 'My strings are: Chars &times; &copy;'],
  //   typeSpeed: 0,
  //   backSpeed: 0,
  //   smartBackspace: true, // this is a default
  //   loop: true
  // });

  test = ['Im Programmer', 'IOS Developer','Freelancer', 'Photographer'];

  constructor() { }

  ngOnInit() {
  }

}
