import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router'
import { ContactUsService } from './contact-us-service.service'
import { contactUs } from './contact-us'

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  public loading: boolean;

  constructor(private fb:FormBuilder,private router:Router,private contactUsService:ContactUsService) { }

  ngOnInit() {
  }

  contactForm = this.fb.group({
    name: ['',[
      Validators.required, 
      Validators.pattern("^[a-zA-Z0-9-.'\\s]*$"), 
      Validators.maxLength(30),
      Validators.minLength(3)]
    ],
    email: ['',[
      Validators.required,
      Validators.maxLength(100),
      Validators.pattern('^.+@.+\\..+$'),
    ]],
    subject: ['',[
      Validators.required, 
      Validators.maxLength(100),
      Validators.minLength(3)]
    ],
    message: ['', Validators.required],
  });

  formSubmit(){
    if(this.contactForm.status === 'INVALID'){
      Object.keys(this.contactForm.controls).forEach((key) => {
        this.contactForm.get(key).markAsDirty();
      });
    }else{
      console.log(this.contactForm.value)
      let contactFormValues:contactUs = this.contactForm.value;
      this.loading = true;
      this.contactUsService.contactUs(contactFormValues).subscribe(res=>{
          let data = res;
          this.router.navigate(['/thank-you']);
          this.loading = false;
      }, 
      error => {
          this.loading = false;
      });
      
    }
  }

}
