import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../common/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

  isHome = false;

  constructor(commonService:CommonService) { 
    commonService.isHome.subscribe(res => {
      this.isHome = res;
    })
    
  }

  ngOnInit() {
  }

}
