import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-development',
  templateUrl: './mobile-development.component.html',
  styleUrls: ['./mobile-development.component.css']
})
export class MobileDevelopmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
