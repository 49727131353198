import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { CommonService } from '../app/components/common/common.service';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit{
  title = 'weoto';

  constructor(@Inject(WINDOW) private window: Window, private router: Router, private commonService:CommonService) { 
    
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        console.log(event.url)
        if(event.url == '/home' || event.url == '/'){
          commonService.isHome.next(true);
        }else{
          commonService.isHome.next(false);
        }
      }
     
    });
  }

  ngOnInit(){
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../assets/js/main.js';
    script.async = false;
    script.defer = true;
    body.appendChild(script);

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      this.window.scrollTo(0, 0)
  });
  } 

}

